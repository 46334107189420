import { geoLocationFilter } from "./geoLocationFilter";

export function nearbySearchResults(context, geoPosition) {
    const breitengrad = geoPosition.latitude;
    const laengengrad = geoPosition.longitude;

    const umkreisFilialen = geoLocationFilter(context.filialenData, breitengrad, laengengrad, "nearbySearch");
    sortDOMAndShowDistanceFlag(context, umkreisFilialen);
  
    const filialenWithinDistance = filterAbholfilialenWithinDistance(umkreisFilialen, 20);
    return filialenWithinDistance.length >= 5 ? filialenWithinDistance : umkreisFilialen.slice(0, 5);
}


function sortDOMAndShowDistanceFlag(context, filialenSortedByDistance) {
    const filialContainer = context.abholfilialenContainer;
    const umkreisFilialenContainer = context.umkreisFilialenContainer;
  
    filialenSortedByDistance.map((filiale) => {
      const distance = Math.round(filiale.distance);
      const filialeDOM = filialContainer.querySelector('[data-filial-id="' + filiale.id + '"]');
      filialeDOM.querySelector(".distance-flag").innerText = "~ " + distance + " km";
      filialeDOM.setAttribute("data-distance", distance);
      filialContainer.appendChild(filialeDOM);
    })
    filialContainer.appendChild(umkreisFilialenContainer);
}

function filterAbholfilialenWithinDistance(filialenWithDistance, distance) {
    return filialenWithDistance.filter(
      (abholfilialeWithDistance) => abholfilialeWithDistance.distance <= distance,
    );
}

export function resetNearbyErrorMsg(context) {
    context.errorUmkreissuche.classList.add("hidden");
}