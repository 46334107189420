import { geoLocationFilter } from "./geoLocationFilter"

export function isPostalCode(mandant, searchString) {
  let postalcodeRegex;
  if (mandant.startsWith("2") || mandant.startsWith("51")) {
    postalcodeRegex = /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/g;
  } else if (mandant.startsWith("4") || mandant.startsWith("37")) {
    postalcodeRegex = /^(?!1000|9999)([1-9]\d{3})$/g;
  }
  
  if (searchString.match(postalcodeRegex)) {
    return true
  } else {
    return false;
  }
}

export function showUmkreisfilialen(context, filterdFilialen, searchString) {
  const alleFilialen = context.filialenData;
  const hiddenFilialen = getNegativeFromSearchList(alleFilialen, filterdFilialen);
  
  fetch(`/api/geodaten/plz/${searchString}`)
  .then((response) => response.json())
  .then((response) => {
    const geoPosition = response[0];
    if (typeof geoPosition !== "undefined") {
        umkreisfilialenWithPostalCode(context.content, hiddenFilialen, geoPosition);
        context.umkreisFilialenContainer.classList.remove("hidden");
    } else {
        return;
    }
  });
}

export function getNegativeFromSearchList(filialen, filterdFilialen) {
  filterdFilialen.forEach(filteredFiliale => {
    filialen = deleteFilteredFiliale(filialen, filteredFiliale.id)
  });
  return filialen;
}

function deleteFilteredFiliale(filialen, filteredFilialId) {
  return filialen.filter(
    (filiale) => filiale.id != filteredFilialId
  );
}

function umkreisfilialenWithPostalCode(dialogContent, filialliste, geoPosition) {
  const filialenContainer = dialogContent.querySelector(".filialen");
  const umkreisFilialenContainer = dialogContent.querySelector(".filialen-umkreis");

  const umkreisFilialen = geoLocationFilter(filialliste, geoPosition.breitengrad, geoPosition.laengengrad, "postalcodeUmkreis");
  
  umkreisFilialen.forEach(filiale => {
      return copyDOMElement(filialenContainer, umkreisFilialenContainer, filiale);
  });
}

export function copyDOMElement(filialenContainer, umkreisFilialenContainer, filiale) {
  const domElement = filialenContainer.querySelector("[data-filial-id='"+ filiale.id +"']").cloneNode(true);
  domElement.setAttribute("hide", "false");
  
  const distance = Math.round(filiale.distance);
  domElement.querySelector(".distance-flag").innerText = "~ " + distance + " km";
  domElement.setAttribute("data-distance", distance);

  umkreisFilialenContainer.appendChild(domElement);
}


export function clearUmkreisfilialen(umkreisFilialenContainer) {
  const umkreisfilialen = umkreisFilialenContainer.querySelectorAll(".filiale");
  umkreisfilialen.forEach(filiale => {
    filiale.remove();        
  });
  if (!umkreisFilialenContainer.classList.contains("hidden")) {
    umkreisFilialenContainer.classList.add("hidden");
  }
}  