export function getFilialenFromDOM(context) {
    const DOMFilialen = context.querySelectorAll(".filiale");
    let filialen = [];
    DOMFilialen.forEach((filiale) => {
      filialen.push({ 
        "id":filiale.dataset.filialId, 
        "name":filiale.dataset.name, 
        "namezusatz":filiale.dataset.namezusatz, 
        "strasse":filiale.dataset.strasse,
        "plz":filiale.dataset.plz, 
        "ort":filiale.dataset.ort, 
        "abholstation":filiale.dataset.abholstation, 
        "laengengrad":filiale.dataset.laengengrad, 
        "breitengrad":filiale.dataset.breitengrad,
        "vorraetig":filiale.dataset.vorraetig,
        "abholaussage":filiale.dataset.abholaussage,
        "abholaussageAbfragen":filiale.dataset.abholaussageAbfragen,
        "filialabholungMoeglich":filiale.dataset.filialabholungMoeglich
      });
    });
    return filialen;
  }