import { calculateAccurateDistance } from "../../common/geolocation";

export function geoLocationFilter(filialliste, breitengrad, laengengrad, returnTo) {
    const filialenSortedByDistance = sortFilialenByDistance(filialliste, breitengrad, laengengrad);
    if (returnTo === "postalcodeUmkreis") {
      return getFirstFilialen(filialenSortedByDistance, 5);
    } else if ( returnTo === "nearbySearch" ) {
      return filialenSortedByDistance;
    }
}

function sortFilialenByDistance(filialliste, startLat, startLon) {
    const filialenWithDistance = filialliste.map((filiale) => {
      const distance = calculateAccurateDistance(
        filiale.breitengrad,
        filiale.laengengrad,
        startLat,
        startLon,
      );
      return { ...filiale, distance };
    });
  
    return filialenWithDistance.sort(
      (filiale1, filiale2) => (filiale1.distance > filiale2.distance ? 1 : -1),
    );
}
  
function getFirstFilialen(filialenSortedByDistance, qty) {
    return filialenSortedByDistance.slice(0, qty);
}