export function filterFilialen(filialenData, searchString) {
  const lowerCaseSearchString = searchString.toLowerCase();

  return filialenData.filter(
    (filiale) => filiale.plz && filiale.plz.startsWith(lowerCaseSearchString)
      || filiale.ort && filiale.ort.toLowerCase().includes(lowerCaseSearchString)
      || filiale.name && filiale.name.toLowerCase().includes(lowerCaseSearchString)
      || filiale.namezusatz && filiale.namezusatz.toLowerCase().includes(lowerCaseSearchString)
  );
}

export function resetSearchResult(context) {
  context.querySelectorAll(".filiale").forEach(filiale => filiale.setAttribute("hide", "false"));
}

export function showFilteredFilialenInDOM(context, filialenToBeShown) {
  context.querySelectorAll(".filiale").forEach(filiale => filiale.setAttribute("hide", "true"));
  filialenToBeShown.forEach(filiale => {
    context.querySelector("[data-filial-id='" + filiale.id + "']").setAttribute("hide", "false");
  });

  const errorKeineFiliale = context.errorKeineFiliale;
  if (filialenToBeShown.length === 0 ? errorKeineFiliale.classList.remove("hidden") : errorKeineFiliale.classList.add("hidden"));
  
  const searchString = context.inputSearch.value;
  const infoPlzVervollstaendigen = context.plzVervollstaendigen;
  const maxLength = maxLengthMandant(context);
  if(!isNaN(searchString) && searchString.length < maxLength) {
    infoPlzVervollstaendigen.classList.remove("hidden");
  } else {
    infoPlzVervollstaendigen.classList.add("hidden");
  }
}

function maxLengthMandant(context) {
  if (context.mandant === "2" || context.mandant === "51") {
    return 5;
  } else {
    return 4;
  }
}