import { getPosition } from "../../common/geolocation";
import { filterFilialen, showFilteredFilialenInDOM, resetSearchResult } from "./filialenFilter";
import { nearbySearchResults, resetNearbyErrorMsg } from "./gpsFilter";
import { handleFilialauswahlClick } from "./main";
import { clearUmkreisfilialen, isPostalCode, showUmkreisfilialen } from "./plzFilter";

export {
  createSearchListener,
  createNearbySearchListener,
  createFilialenClickListener,
  createCancelListener,
};

const AKTUELLER_STANDORT_TEXT = "Ihr aktueller Standort";

function createSearchListener(context) {
  return () => {
    resetNearbyErrorMsg(context);
    const searchString = context.inputSearch.value;
    if (searchString.includes(AKTUELLER_STANDORT_TEXT)) {
      return;
    }
    const filterdFilialen = filterFilialen(context.filialenData, searchString);
    showFilteredFilialenInDOM(context, filterdFilialen);

    if (isPostalCode(context.mandant, searchString)) {
      showUmkreisfilialen(context, filterdFilialen, searchString);
    } else {
      clearUmkreisfilialen(context.umkreisFilialenContainer);
    }
  };
}

function createNearbySearchListener(context) {
  return async () => {
    context.inputSearch.value = AKTUELLER_STANDORT_TEXT;
    resetSearchResult(context);
    clearUmkreisfilialen(context.umkreisFilialenContainer);
    hide(context.abholfilialenContainer);
    show(context.loadingIndicator);
    try {
      const positionOptions = {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 0,
      };
      const geoPosition = (await getPosition(positionOptions)).coords;
      const filialenToBeShown = nearbySearchResults(context, geoPosition);
      showFilteredFilialenInDOM(context, filialenToBeShown);
    } catch (e) {
      context.errorUmkreissuche.classList.remove("hidden");
      throw e;
    } finally {
      hide(context.loadingIndicator);
      show(context.abholfilialenContainer);
    }
  };
}

async function createFilialenClickListener(dialog, event) {
  const target = event.target;
  if (target.classList.contains("abholaussageAbfragen")) {
    await getAbholaussage(target);
  }
  if (target.tagName === "span" && target.closest("p").classList.contains("abholaussageAbfragen")) {
    await getAbholaussage(target.closest("p"));
  }
  if (target.classList.contains("filiale-waehlen")) {
    const filiale = target.closest("article");
    handleFilialauswahlClick(dialog, filiale);
  }
}

async function getAbholaussage(target) {
  const abholaussageParagraph = target;
  const filialId = target.closest("article").getAttribute("data-filial-id");
  const artikelnummer = target.closest(".filialen").getAttribute("artikelnummer");
  try {
    abholaussageParagraph.classList.remove("abholaussageAbfragen");
    const response = await fetch(`/api/filialabholung/${filialId}/abholaussage/${artikelnummer}`);
    const responseJson = await response.json();
    abholaussageParagraph.querySelector(".abholinfo-text").innerHTML = responseJson.abholaussage;
    abholaussageParagraph.setAttribute("data-typ", "status-gelb");
  } catch (err) {
    abholaussageParagraph.classList.add("abholaussageAbfragen");
    console.error(err);
  }
}

function createCancelListener(dialog) {
  return () => {
    dialog.close();
  };
}

function hide(element) {
  element.classList.add("hidden");
}

function show(element) {
  element.classList.remove("hidden");
}
