// @ts-check

export { isXcaAndroidWebView };

/**
 * @param {string} useragent
 * @return {boolean}
 */
function isXcaAndroidWebView(useragent) {
  const lowerCaseUserAgent = useragent.toLowerCase();

  return (lowerCaseUserAgent.includes("xca/") || lowerCaseUserAgent.includes("oft/") || lowerCaseUserAgent.includes("osi/"))  && lowerCaseUserAgent.includes("android");
}