function toRadians(degrees) {
  return degrees * Math.PI / 180;
}

export function getPosition(options = undefined) {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}

/**
 * Berechnet die Distanz mit der Haversine-Formel
 */
export function calculateAccurateDistance(startLat, startLon, endLat, endLon) {
  const R = 6371e3;
  const radLat1 = toRadians(startLat);
  const radLat2 = toRadians(endLat);
  const dRadLat = toRadians(endLat - startLat);
  const dRadLon = toRadians(endLon - startLon);

  const a = Math.sin(dRadLat / 2) * Math.sin(dRadLat / 2)
      + Math.cos(radLat1) * Math.cos(radLat2) * Math.sin(dRadLon / 2) * Math.sin(dRadLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c / 1000;
}
