import { getFilialenFromDOM } from "../../common/getFilialen";
import { isXcaAndroidWebView } from "../../common/useragent";
import { WebComponent } from "../../common/WebComponent";
import {
  createFilialenClickListener,
  createCancelListener,
  createNearbySearchListener,
  createSearchListener
} from "./filialenListener";
import {setCookie} from "../../common/setCookie";

export class FilialeAuswahl extends WebComponent {
  connectedCallback() {
    this.declareElements();
    this.declareAndRegisterListeners();

    // Temporärer Fix für Android Webview [PAC-5681]
    if (isXcaAndroidWebView(navigator.userAgent)) {
      this.btnNearbySearch.style.display = "none";
    }

    if (localStorage.uc_user_interaction) {
      this.openMaps.classList.remove('hidden');
    }

    this.filialenData = getFilialenFromDOM(this);
    this.inputSearch.focus();
  }

  declareElements() {
    this.wrapper = this.querySelector(".wrapper");
    this.btnNearbySearch = this.wrapper.querySelector(".btn-nearby-search");
    this.inputSearch = this.wrapper.querySelector(".input-search");
    this.abholfilialenContainer = this.wrapper.querySelector(".filialen");
    this.umkreisFilialenContainer = this.wrapper.querySelector(".filialen-umkreis");
    this.loadingIndicator = this.wrapper.querySelector(".content-loading");
    this.errorKeineFiliale = this.wrapper.querySelector(".error-keine-filiale");
    this.plzVervollstaendigen = this.wrapper.querySelector(".plz-vervollstaendigen");
    this.errorUmkreissuche = this.wrapper.querySelector(".error-umkreissuche");
    this.content = this.querySelector(".content");
    this.openMaps = this.wrapper.querySelector(".btn-maps");
    this.closeButton = this.wrapper.querySelector(".icon-close");
    this.dialog = this.wrapper.closest(".filialeBearbeitenDialog");
    this.mandant = this.getAttribute("mandant");
  }

  declareAndRegisterListeners() {
    this.addListener(this.closeButton, "click", createCancelListener(this.dialog));
    const searchListener = createSearchListener(this);
    this.addListener(this.inputSearch, "input", searchListener);
    this.addListener(this.inputSearch, "search", (e) => {
      searchListener();
      this.inputSearch.blur();
    });
    this.addListener(this.btnNearbySearch, "click", createNearbySearchListener(this));

    if (localStorage.uc_user_interaction) {
      this.addListener(this.openMaps, "click", () => this.showOrHideMaps());
    }

    this.addListener(this.abholfilialenContainer, "click", (event) => {
      createFilialenClickListener(this.dialog, event);
    });
  }

  showOrHideMaps() {
    if (!localStorage.uc_user_interaction) return;

    const dialog = this.wrapper;
    const mapsKomponente = this.querySelector("googlemaps-filialauswahl");

    const btn = this.querySelector(".btn-maps");
    const btnText = btn.querySelector(".button-text");
    (btnText.textContent === 'Karte einblenden') ? btnText.textContent = 'Karte ausblenden' : btnText.textContent = 'Karte einblenden';

    dialog.classList.toggle("mapAktiv");
    mapsKomponente.setAttribute('anzeigen', 'ja');
  }
}


export function handleFilialauswahlClick(dialog, filiale) {
  setCookie("such_abholfiliale", filiale.dataset.filialId);
  dialog.dispatchEvent(new CustomEvent("filiale-gewaehlt", {
    detail: {
      filialid: filiale.dataset.filialId,
      filialname: filiale.dataset.name,
      filialnameZusatz: filiale.dataset.namezusatz
    }, bubbles: true }));
  dialog.close();
}

if (!customElements.get("filiale-auswahl")) {
  customElements.define("filiale-auswahl", FilialeAuswahl);
}
