const { loadAndShowDialog } = require("../filiale-auswahl/dialog");

class SuchergebnisFilialauswahl extends HTMLElement {
  connectedCallback() {
    this.dialog = this.querySelector(".filialeBearbeitenDialog");
    this.openFrom = this.querySelector("div[open-dialog-from]").getAttribute("open-dialog-from");
    document.addEventListener("openFilialauswahlDialog", (event) => { this.openDialog(event) });
  }

  async openDialog(event) {
    let retries = 3;
    let success = false;
    while (retries-- > 0 && !(success = await loadAndShowDialog(event.detail.artikelnummer, this.dialog, this.openFrom))) {}
  }
}

if (!customElements.get("suchergebnis-filialauswahl")) {
  customElements.define("suchergebnis-filialauswahl", SuchergebnisFilialauswahl);
}
