import { replaceHtml } from "../../common/helper";
import { WebComponent } from "../../common/WebComponent";
import { getDialogContent } from "../../common/api";

class FilialauswahlDialog extends WebComponent {
  connectedCallback() {
    this.declareElements();
    this.declareAndRegisterListeners();
  }
  declareElements() {
    this.dialogParent = this.closest("[dialogParent]");
    this.dialog = this.querySelector(".filialeBearbeitenDialog");
    this.inputSearch = this.dialog.querySelector(".input-search");
    if(this.dialog.closest("div[artikelnummer]")) {
      this.artikelnummer = this.dialog.closest("div[artikelnummer]").getAttribute("artikelnummer");
    } else { this.artikelnummer = "" }
    this.openFrom = this.dialog.closest("div[open-dialog-from]").getAttribute("open-dialog-from");
  }
  
  async declareAndRegisterListeners() {
      this.dialogParent.addEventListener("click", async (event) => { await this.handleClick(event, this.artikelnummer);});
  }

  async handleClick(event, artikelnummer) {
    if (event.target.closest(".filiale-aendern")) {
      event.preventDefault();
      if (this.dialog.childNodes.length !== 0) {
        this.dialog.showModal();
        return;
      }
  
      let retries = 3;
      let success = false;
      while (retries-- > 0 && !(success = await loadAndShowDialog(artikelnummer, this.dialog, this.openFrom))) {}
    }
  }
}

if (!customElements.get("filialauswahl-dialog")) {
  customElements.define("filialauswahl-dialog", FilialauswahlDialog);
}

export async function loadAndShowDialog(artikelnummer, dialog, openFrom) {
  try {
    const response = await getDialogContent(artikelnummer, openFrom);
    const html = await response.text();
    replaceHtml(dialog, html);
    dialog.showModal();
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
