/* eslint-disable no-bitwise */
export { get };

async function get(
  url,
  acceptMIMEType,
  timeout,
  authRedirectUrl
) {
  if (authRedirectUrl) {
    return handleRedirect(
      rejectOnError(getFetch(url, acceptMIMEType, timeout)),
      authRedirectUrl
    );
  }
  return rejectOnError(getFetch(url, acceptMIMEType, timeout));
}
async function getFetch(
    url,
    acceptMIMEType,
    timeout
  ) {
    const paramGet = {
      credentials: "include",
      method: "GET",
      signal: getSignal(timeout),
      mode: "no-cors",
      headers: { Accept: acceptMIMEType },
    };
  
    return fetch(url, paramGet);
  }

async function rejectOnError(response) {
    const res = await response;
    if (!res.ok) {
      return Promise.reject(res);
    }
  
    return response;
  }
  
  function handleRedirect(
    response,
    authRedirectUrl
  ) {
    return response.catch((e) => {
      if (e instanceof Response && (e.status === 401 || e.status === 403)) {
        window.location.assign(authRedirectUrl);
      }
      return Promise.reject(e);
    });
  }

  function getSignal(timeout) {
    let timeoutSignal;
    if (timeout) {
      const timeoutAbortController = new AbortController();
      setTimeout(() => timeoutAbortController.abort(), timeout);
      timeoutSignal = timeoutAbortController.signal;
    }
    return timeoutSignal;
  }