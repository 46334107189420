export class WebComponent extends HTMLElement {
  /**
   *
   * @param {HTMLElement | Window} element
   * @param {string} type
   * @param {EventListenerOrEventListenerObject} listener
   */
  addListener(element, type, listener) {
    this.listeners = this.listeners || [];
    this.listeners.push({ listener, element, type });
    element.addEventListener(type, listener);
  }

  disconnectedCallback() {
    this.listeners.forEach(({ element, type, listener }) => {
      element.removeEventListener(type, listener);
    });
  }
}
